export const SANITIZE_TEXT_REGEX = /[^a-zA-Z<>&@.-\d\s:]/g
export const SANITIZE_NUMBER_REGEX = /[^0-9]/g
export const PDP_REGEX = /^([\/]).*.([\-]).([0-9]{2,}).*$/gm
export const MEMBERSHIP_VALID_MAIL_ERROR_REGEX = /\S+@\S+\.\S+/
export const MEMBERSHIP_CODE_ERROR_REGEX = /@kohler.com\s*$/
export const FULL_NAME_REGEX = /^[a-zA-Z]+ [a-zA-Z]+$/
export const PURE_TEXT_REGEX = /<[^>]*>/g
export const SPECIAL_CHAR_REGEX = /[^a-zA-Zd0-9 ]/g
export const ALPHABET_WITH_SPACE_REGEX = /^[a-zA-Z ]+$/
export const POSTAL_CODE_US_REGEX = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/
export const POSTAL_CODE_CA_REGEX = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/
export const POSTAL_CODE_REGEX = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/
export const INCH_SYMBOL_REGEX = /\d$/
